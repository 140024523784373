// thirdParty Imports  :
import React from "react";
import { Routes, Route } from "react-router-dom";
import { useContext } from "react";
import { Grid } from "@mui/material";

// Local Files Imports :
import "./App.scss";
import "./style/dark.scss";
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/auth/login/loginPage";
import SignUp from "./pages/auth/signup/signUpPage";
import strings from "./assets/locals/locals";
import ProtectedRoute from "./pages/auth/protectedRoutes/protectedRoutes";
import Items from "./pages/item/items";
import AddItem from "./pages/item/addItem/addItem";

import LanguageContext from "./context/langContext";
import DarkModeContext from "./context/darkModeContext";
import Categories from "./pages/category/categories";
import AddCategory from "./pages/category/addCategory/addCategory";
import axios from "axios";
import Kitchen from "./pages/kitchen";
import Hall from "./pages/hall/hall";
import AddAdjustments from "./pages/item/addItem/addAdjustments";
import OrdersContext from "./context/orders";
import HomePage from "./pages/home/home";

import "./App.css";
import Policy from "./pages/policy/policy";
import HomePagePolicy from "./pages/policy/homePagePolicy";
import PaymentHistory from "./pages/paymentHistory/paymentHistory";
import { ToastContainer } from "react-toastify";
import MakeOrder from "./pages/makeOrder/makeOrder";

axios.defaults.baseURL = "https://silinbakeri.net/php";

const App = () => {
  const { darkMode } = useContext(DarkModeContext);
  const { lang } = useContext(LanguageContext);
  const { submittedOrders, notFinishedOrders } = useContext(OrdersContext);
  strings.setLanguage(lang);

  return (
    <Grid
      container
      spacing={0}
      className={darkMode ? "app dark" : "app"}
      dir={lang === "ar" ? "rtl" : "ltr"}
      minHeight={"100vh"}
    >
      <Routes>
        <Route index exact path="/" element={<HomePage />} />
        <Route exact path="/our-policy" element={<HomePagePolicy />} />

        <Route exact path="/2hvcEh7TidLCu7" element={<Login />} />

        <Route
          path="/sign-up"
          element={<ProtectedRoute component={<SignUp />} />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute component={<Dashboard />} />}
        />
        <Route
          path="/items"
          element={<ProtectedRoute component={<Items />} />}
        />
        <Route
          path="/items/add"
          element={<ProtectedRoute component={<AddItem />} />}
        />
        <Route
          path="/items/add-adjustment"
          element={<ProtectedRoute component={<AddAdjustments />} />}
        />

        <Route
          path="/items/:id"
          element={<ProtectedRoute component={<AddItem />} />}
        />
        <Route
          path="/categories"
          element={<ProtectedRoute component={<Categories />} />}
        />
        <Route
          path="/categories/add"
          element={<ProtectedRoute component={<AddCategory />} />}
        />
        <Route
          path="/categories/:id"
          element={<ProtectedRoute component={<AddCategory />} />}
        />
        <Route
          path="/policy"
          element={<ProtectedRoute component={<Policy />} />}
        />
        <Route
          path="/payment-history"
          element={<ProtectedRoute component={<PaymentHistory />} />}
        />
        <Route
          path="/make-order"
          element={<ProtectedRoute component={<MakeOrder />} />}
        />

        <Route
          exact
          path="/not-finished-orders"
          element={<Kitchen orders={notFinishedOrders} />}
        />
        <Route
          exact
          path="/submitted-orders"
          element={<Kitchen orders={submittedOrders} />}
        />

        <Route exact path="/hall" element={<Hall />} />
      </Routes>
      <ToastContainer />
    </Grid>
  );
};
export default App;
