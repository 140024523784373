import Navbar from "../../components/navbar/homePageNavbar/homePageNavbar"
import { Footer } from "../../container"
import Policy from "./policy"
import { Grid } from "@mui/material";


const HomePagePolicy = () => {
    return (
        <Grid container>
            <Navbar />
            <Policy />
            <Footer />
        </Grid>
    )
}

export default HomePagePolicy;