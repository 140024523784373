import React, { useContext, useState, useEffect } from "react";

import { SubHeading, MenuItem } from "../../components";
import {images } from "../../constants";
import "./SpecialMenu.css";
import CategoriesContext from "../../context/categories";
import ItemsContext from "../../context/itemsContext";


const SpecialMenu = () => {
  const { categories } = useContext(CategoriesContext)
  const { items } = useContext(ItemsContext);
const [itemsOfFirstCategory , setItemsOfFirstCategory] = useState([]);
const [itemsOfSecondCategory , setItemsOfSecondCategory] = useState([]);  

  useEffect(()=>{
    if (categories.length > 1 && items){
      let temp = items.filter(el=>el.CategoryID == categories[0].CategoryID)
      setItemsOfFirstCategory(temp);
      if (categories[3]){
        let temp2 = items.filter(el=>el.CategoryID == categories[3].CategoryID)
        setItemsOfSecondCategory(temp2);
      }
      
    }
  },[items , categories])
  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <SubHeading title="Menu that fits your palatte" />
        <h1 className="headtext__cormorant">Today&apos;s Special</h1>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_wine  flex__center">
          <p className="app__specialMenu-menu_heading">{categories && categories[0] && categories[0].CategoryName}</p>
          <div className="app__specialMenu_menu_items">
            {itemsOfFirstCategory.map((item, index) => (
              <MenuItem
                key={item.title + index}
                title={item.ItemName}
                price={item.Price}
                tags={item.tags}
              />
            ))}
          </div>
        </div>

        <div className="app__specialMenu-menu_img">
          <img src={images.menu} alt="menu__img" />
        </div>

        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">{categories && categories[3] && categories[3].CategoryName}</p>
          <div className="app__specialMenu_menu_items">
            {itemsOfSecondCategory.map((item, index) => (
              <MenuItem
                key={item.title + index}
                title={item.ItemName}
                price={item.Price}
                tags={item.tags}
              />
            ))}
          </div>
        </div>
      </div>

      <div style={{ marginTop: 15 }}>
        <button type="button" className="custom__button">
          <a href="https://silin.silinbakeri.net/" target="_blank">View More</a>
        </button>
      </div>
    </div>
  )
}

export default SpecialMenu;
