
import React from "react";

import { Grid } from "@mui/material";

const Policy = () => (
    <Grid container>
        <Grid container className="work-section-wrapper" padding={2}>
            <Grid item xs={12} textAlign={"center"} justifyContent={"center"} display={"flex"} >
                <h2 className="primary-heading"> Kjøpsbetingelser og vilkår </h2>
            </Grid>
            <Grid item xs={12} padding={3}>
                <ol style={{ padding: "20px" }}>
                    <li >
                        <strong>Avtalen</strong>
                        <ul style={{ padding: "20px" }}>
                            <li>
                                Alle bestillinger på nett, app og selvbetjent kasse foregår automatisk.
                                Derfor er det veldig viktig at du som kjøper setter deg inn i kjøpsbetingelsene og vilkårene som gjelder.
                                Betingelsene gjelder både som registrert kunde eller gjest.
                                Ved kjøp av varer på våre plattformer, aksepterer du våre kjøpsbetingelser og det inngås en bindende avtale mellom selger og deg som kunde.
                                Merk at disse betingelsene kan når som helst endres, og du som kjøper bør derfor lese disse betingelsene hver gang du gjennomfører et kjøp.
                            </li>
                        </ul>
                    </li>
                    <li >
                        <strong>Hvem er vi?</strong>
                        <ul style={{ padding: "20px" }}>
                            <li>
                                Selger:
                                <ul style={{ padding: "20px" }}>
                                    <li>Grønlandsleiret 61C</li>
                                    <li>Organisasjonsnummer: 931428810</li>
                                </ul>
                            </li>
                            <li>
                                Adresse:
                                <ul style={{ padding: "20px" }}>
                                    <li>Juridisk navn: BAKERI MANDO (Silin Bakeri)</li>
                                    <li>0190 OSLO</li>
                                    <li>Norge</li>

                                </ul>
                            </li>
                            <li>
                                Kontakt:
                                <ul style={{ padding: "20px" }}>
                                    <li>E-post: Bakerimando@gmail.com</li>
                                    <li>Telefonnummer: 96878984</li>
                                    <li>Selgeren betegnes i det følgende som selger/selgeren.</li>

                                </ul>
                            </li>

                        </ul>
                    </li>

                    <li >
                        <strong>Pris</strong>
                        <ul style={{ padding: "20px" }}>
                            <li>Den oppgitte prisen for varen er den totale prisen kjøper skal betale og inkluderer merverdiavgift. </li>
                            <li>
                                Vi tar forbehold for prisendringer og prisfeil på våre produkter og tjenester.
                                Merk at tilbud gjelder så langt lageret rekker og innenfor de tidsrammer som det informeres om i vår markedsføring.
                            </li>

                        </ul>
                    </li>

                    <li >
                        <strong>Betaling</strong>
                        <ul style={{ padding: "20px" }}>
                            <li>Selgeren kan kreve betaling for varen fra bestillingstidspunktet eller ved utlevering av varen. </li>
                            <li>
                                Dersom kjøperen bruker kredittkort, debetkort eller Vipps ved betaling, kan selgeren reservere kjøpesummen på kortet ved bestilling.
                                Kortet blir belastet ved bestilling.
                            </li>
                            <li>
                                Ved betaling med faktura, blir fakturaen til kjøperen utstedt i henhold til avtale med brukerstedet.
                                Betalingsfristen fremgår av fakturaen og er på minimum 14 dager fra mottak.
                            </li>
                            <li>
                                Kjøpere under 18 år kan ikke betale med etterfølgende faktura.
                            </li>

                        </ul>
                    </li>



                    <li >
                        <strong>Angrerett</strong>
                        <ul style={{ padding: "20px" }}>
                            <li>
                                Kjøper kan når som helst ombestemme seg og benytte seg av sin angrerett ved å heve kjøpet innen 14 dager etter mottakelse av varen.
                                Unntak fra angreretten gjelder ikke kjøp av måltider,
                                dagligvarer og andre varer med kort levetid,
                                varer som av naturlige årsaker ikke kan returneres eller som forringes eller raskt går ut på dato,
                                eller i andre situasjoner hvor det foreligger et eksplisitt unntak under gjeldende forbrukerlovgivning.
                            </li>
                            <li>
                                For enkelte varer av mer varig karakter har kjøper en angrerett på 14 dager fra varen er mottatt.
                                Kjøper kan benytte seg av kontaktinformasjonen tilgjengelig på denne siden for å benytte seg av angreretten.
                            </li>
                            <li>
                                Kjøper sin rett til å trekke seg fra kjøpet og motta tilbakebetaling forutsetter at produktet er i uendret stand,
                                med originalemballasjen og at forseglingen ikke er brutt.
                            </li>
                            <li>
                                Les mer om angrerettloven og unntakk på lovdata.no.
                            </li>

                        </ul>
                    </li>



                    <li >
                        <strong>Levering</strong>
                        <ul style={{ padding: "20px" }}>
                            <li> Levering er skjedd når kjøperen, eller hans representant, har overtatt tingen.</li>
                            <li> Varen skal leveres hos selgeren med mindre annet er særskilt avtalt mellom partene</li>
                            <li> Varer som ikke blir hentet på utleveringssted blir belastet med bestilt beløp.</li>
                        </ul>
                    </li>





                    <li >
                        <strong>Begrenset ansvar</strong>
                        <ul style={{ padding: "20px" }}>
                            <li> INGEN GARANTI
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        Selger vil alltid gjøre sitt beste for å sikre at nettside og app fungerer som tiltenkt.
                                        Imidlertid, og på grunn av internettets natur kan vi ikke garantere at våre tjenester er fri for forsinkelser, avbrytelser eller feil.
                                        Vi kan heller ikke garantere at tjenesten er fri for virus eller annet uønsket innhold.
                                        Selger kan ikke belastes for kostnader knyttet til service eller erstatning av utstyr på bakgrunn av bruk av våre tjenester.
                                        Dette gjelder alle aspekter av tjenesten, inkludert software, tekst, bilder og linker.
                                    </li>

                                </ul>
                            </li>
                            <li>FORCE MAJEURE
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        Dersom selger på grunn av forhold utenfor vår kontroll (force majeure),
                                        ikke er i stand til å gjennomføre levering i henhold til forutsetningene i bestillingen,
                                        er selger ikke ansvarlig for skade eller ulempe som voldes kunde.
                                        Selger skal umiddelbart underrette kjøperen dersom slike forhold skulle inntreffe.
                                    </li>

                                </ul>
                            </li>

                            <li>ANSVARLIG FOR INNHOLD
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        Selger gjør sitt ytterste for at all informasjon vi gir gjennom våre tjenester til enhver tid er korrekte.
                                        Selger tar imidlertid ikke ansvar for feil i menyen, skrivefeil, utdaterte priser, forsinket respons (e-post, sms mm),
                                        heller ikke bestillinger eller beskjeder som ikke kommer frem til oss.
                                    </li>

                                </ul>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <strong>Generell erklæring om personvern, personopplysninger og informasjonskapsler</strong>
                        <ul style={{ padding: "20px" }}>
                            <li>
                                Vi verdsetter ditt personvern og ønsker å informere deg om hvordan vi samler inn, bruker og beskytter dine personopplysninger .
                            </li>
                            <li>
                                Personopplysninger:
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        Vi samler kun inn nødvendige personopplysninger for å gi deg en bedre opplevelse på vår nettside.
                                        Dine opplysninger vil aldri deles med tredjeparter uten ditt samtykke, med mindre det er påkrevd av loven.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Personvern:
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        Vi tar sikkerheten til dine personopplysninger på alvor.
                                        Vår nettside er utstyrt med nødvendige sikkerhetstiltak for å beskytte dine opplysninger mot uautorisert tilgang,
                                        endring eller ødeleggelse.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Informasjonskapsler (Cookies):
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        For å forbedre din opplevelse og tilpasse innholdet etter dine preferanser,
                                        bruker vi informasjonskapsler. Disse små tekstfilene lagres på enheten din og hjelper oss med å analysere nettsidens
                                        trafikk og forstå hvordan besøkende samhandler med nettsiden
                                    </li>
                                </ul>
                            </li>



                            <li>
                                Takk for at du besøker nettsiden vår, og hvis du har spørsmål angående personvern eller informasjonskapsler, vennligst kontakt oss.
                            </li>

                        </ul>
                    </li>



                    <li>
                        <strong>Konfliktløsning - Klager, tvister og lover</strong>
                        <ul style={{ padding: "20px" }}>
                            <li>
                                Partene skal forsøke å løse eventuelle tvister i minnelighet.
                            </li>
                            <li>
                                KLAGER:
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        Klager på eventuelle mangler og feil sendes til kontaktinformasjonen over snarest mulig og innen rimelig tid.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                TVISTER OG LOVER:
                                <ul style={{ padding: "20px" }}>
                                    <li>
                                        Norsk lov skal gjelde for alle kjøp gjort under disse vilkårene.
                                        Tvister om kjøp under disse vilkår skal utelukkende behandles av en Norsk domstol.
                                    </li>
                                    <li>
                                        Sist endret: 24.11..23 - 15:34
                                    </li>
                                </ul>
                            </li>


                        </ul>
                    </li>
                </ol>

            </Grid>
        </Grid>

    </Grid>
);

export default Policy;
