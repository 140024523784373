import React, { useContext } from "react";
import { Box, Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";

import Logo from "../../../assets/images/logo.png";
import "./signUpPage.css";
import strings from "../../../assets/locals/locals";
import LanguageContext from "../../../context/langContext";
import { getInputs, getValidationSchema } from "./configurations";
import UserContext from "../../../context/userContext";
import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
  const { lang } = useContext(LanguageContext);
  const { register, adminType } = useContext(UserContext);
  const navigate = useNavigate();
  strings.setLanguage(lang);

  const inputs = getInputs(lang);
  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      userType: ""
    },
    validationSchema: getValidationSchema(lang),
    onSubmit: (data) => {
      register(data);
    },
  });

  if (adminType === "super") {
    return (
      <div className={"mainContainer"}>
        <Grid
          item
          xs={11}
          lg={8}
          xl={9}
          justifyContent={"space-around"}
          borderRadius={"50px"}
          padding={{ xs: 1, sm: 1, md: 2, lg: 3 }}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} sm={12} lg={6} xl={5}>
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" gutterBottom>
                  {strings.signUp}
                </Typography>
                <Grid container alignItems="center">
                  {inputs.map(({ name, label, id, type, icon, options }) => (
                    <Grid
                      container
                      key={name}
                      gap={{ xs: 0, sm: 0, md: 1, lg: 1 }}
                    >
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={1}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="h6"
                          padding={"2"}
                          color={
                            formik.touched[name] && Boolean(formik.errors[name])
                              ? "#d32f2f"
                              : "#FFB600"
                          }
                        >
                          {icon}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={9}
                        md={9}
                        lg={10}
                        className="text-feild"
                      >
                        <TextField
                          fontSize="15px"
                          key={name}
                          fullWidth
                          id={id}
                          type={type}
                          select={type === "select" ? true : false}
                          name={name}
                          label={label}
                          placeholder={label}
                          margin="normal"
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched[name] && Boolean(formik.errors[name])
                          }

                        >
                          {type === "select" && options.map(opt => <MenuItem key={opt} value={opt}>{opt}</MenuItem>)}
                        </TextField>

                        <Typography
                          variant="p"
                          fontSize={"15px"}
                          textAlign={lang === "ar" ? "right" : "left"}
                          color={"#D32F2F"}
                        >
                          {formik.touched[name] && formik.errors[name]}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </form>

              <Grid container marginTop={4}>
                <Grid item xs={12} md={12} xl={12} lg={12}>
                  <Button
                    variant="contained"
                    onClick={formik.handleSubmit}
                    className="signUpButton"
                  >
                    {strings.register}
                  </Button>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} lg={5} padding={1}>
              <Box
                component="img"
                src={Logo}
                sx={{ height: "450px", width: "100%" }}
                alt="sign up"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  else {
    navigate("/items")

  }
};
export default SignUpPage;
