import { Button, Grid, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useContext } from 'react';
import OrdersContext from '../context/orders';
import strings from "../../src/assets/locals/locals";
import LanguageContext from '../context/langContext';

const OrdersTable = ({ orders }) => {
    const { lang } = useContext(LanguageContext);
    strings.setLanguage(lang);
    const { deleteOrder, checkPaymentStatus } = useContext(OrdersContext);
    return (
        <TableContainer component={Paper} style={{ background: "transparent" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow style={{ fontWeight: "bolder" }}>
                        <TableCell >i</TableCell>
                        <TableCell >{strings.orderNumber}</TableCell>
                        <TableCell >{strings.orderItems}</TableCell>
                        <TableCell >{strings.orderPrice} </TableCell>
                        <TableCell >{strings.orderStatus}</TableCell>
                        <TableCell >{strings.orderTake}</TableCell>
                        <TableCell >{strings.orderDate}</TableCell>
                        <TableCell >{strings.paymentStatus}</TableCell>
                        <TableCell ></TableCell>
                        <TableCell ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((row, index) => {
                        console.log("this is the row ", row);
                        return (
                            <TableRow
                                key={row.OrderID}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{index}</TableCell>
                                <TableCell>{row.OrderNumber}</TableCell>
                                <TableCell>{row.SelectedItems && row.SelectedItems.length > 0 ? row.SelectedItems.map(({ ItemName, Adjustments }, index) => {
                                    return (
                                        <Grid container textAlign={"start"} key={`${row.OrderID} ${ItemName}`}>
                                            <Grid item xs={2} lg={2}>
                                                ({JSON.parse(row.Quantity) !== null && JSON.parse(row.Quantity)[index]})
                                            </Grid>
                                            <Grid item xs={10} lg={10}>
                                                <Typography variant="p" display={"block"}>
                                                    <strong>{ItemName}</strong>
                                                    <ul>
                                                        {Adjustments && typeof (Adjustments) === "string" ? Adjustments : Adjustments.map(el => {
                                                            if (el) {
                                                                return (
                                                                    <li key={el}> {el} </li>
                                                                )
                                                            }
                                                            else {
                                                                return <></>
                                                            }
                                                        })}
                                                    </ul>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                }) : <div> ------ </div>}</TableCell>
                                <TableCell> <Typography variant="span" fontWeight={"bolder"} color="error"> {`${row.TotalAmount} NOK`} </Typography></TableCell>
                                <TableCell>{row.Status === 1 ? `Submitted` : row.Status === 2 ? `Not Finished` : row.Status === 3 ? "Finished" : null}</TableCell>
                                <TableCell>{row.orderTake}</TableCell>
                                <TableCell>{row.OrderDate}</TableCell>
                                <TableCell>{row.paymentStatus}</TableCell>
                                <TableCell>
                                    <Button color='error' variant='outlined' style={{ fontSize: ".7rem", fontWeight: "bolder" }} onClick={() => deleteOrder(row.OrderID)}>
                                        {strings.delete}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {row.paymentStatus !== "ADMIN" && <Button color='warning' variant='outlined' style={{ fontSize: ".7rem" }} onClick={() => checkPaymentStatus(row.orderIdVipps)}>
                                        {strings.checkPaymentStatus}
                                    </Button>}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default OrdersTable;