import React, { useContext, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
// import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";

import Widget from "../../components/widget/Widget";
import LanguageContext from "../../context/langContext";
import strings from "../../assets/locals/locals";
import "./dashboard.scss";
import OrdersContext from "../../context/orders";
import ItemsContext from "../../context/itemsContext";
import CategoriesContext from "../../context/categories";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import OrdersTable from "../../components/ordersTable";
import NoContent from "../../components/noContent";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";


const Dashboard = () => {
  const { lang } = useContext(LanguageContext);
  const { orders, totalPrice } = useContext(OrdersContext);
  const { items } = useContext(ItemsContext)
  const { categories } = useContext(CategoriesContext)
  const [date, setDate] = useState(null);
  const [dateOrders, setDateOrders] = useState(null);
  const [noOrders, setNoOrders] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateTotalPrice, setDateTotalPrice] = useState(null)
  const { adminType } = useContext(UserContext);
  const navigate = useNavigate();
  strings.setLanguage(lang);
  const widgets = [
    {
      title: strings.totalOrders,
      isMoney: false,
      icon: (
        <PersonOutlinedIcon className="icon" style={{ color: "crimson" }} />
      ),
      amount: dateOrders ? dateOrders.length : orders.length
    },
    {
      title: strings.totalItems,
      isMoney: false,
      icon: (
        <ShoppingCartOutlinedIcon
          className="icon"
          style={{
            color: "goldenrod",
          }}
        />
      ),
      amount: items.length
    },
    {
      title: strings.totalPrice,
      isMoney: true,
      icon: (
        <ShoppingCartOutlinedIcon
          className="icon"
          style={{
            color: "goldenrod",
          }}
        />
      ),
      amount: dateTotalPrice === null ? totalPrice : dateTotalPrice
    },
    {
      title: strings.totalCategories,
      isMoney: false,
      icon: (
        <FormatAlignJustifyIcon
          className="icon"
          style={{
            color: "purple",
          }}
        />
      ),
      amount: categories.length,
    }
  ];

  const reset = () => {
    setDateOrders(null);
    setDate(null);
    setDateTotalPrice(null);
  }


  const getOrdersByDate = () => {
    if (date) {
      setLoading(true)
      axios.get(`/YearSlide.php?year=${date.$y}&month=${date.$M + 1}&day=${date.$D}`).then(res => {
        setLoading(false);
        setDateOrders(res.data.orders);
        setDateTotalPrice(res.data.totalPPrice)
        if (res.data.orders.length === 0) {
          setNoOrders(true);
        }
        else {
          setNoOrders(false);
        }
      }).catch(err => {

      })
    }
  }

  if (adminType === "super") {
    return (
      <Grid container className="homeContainer" padding={2}>
        {/* Widget */}
        {widgets.map(({ title, icon, amount }) => {
          return (
            <Widget
              key={title}
              title={title}
              isMoney={false}
              icon={icon}
              amount={amount}
            />
          )
        })}
        <Grid container>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']} >
              <Grid item xs={12} sm={12} md={12}>
                <Grid container>
                  <Grid item xs={12} md={3} py={2} >
                    <Typography variant="h4" textAlign={"start"} py={2}>{strings.getOrdersByDate}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} py={2} display={"flex"} alignItems={"center"}>
                    <DatePicker
                      label={'"Order Date"'}
                      views={['year', 'month', 'day']}
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      className="borderd"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} padding={3} display={"flex"} alignItems={"center"} justifyContent={"space-around"}>
                    <Button variant="outlined" onClick={getOrdersByDate}> {strings.submit}</Button>
                    <Button variant="outlined" color="error" onClick={reset}> {strings.reset}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        {loading && <CircularProgress />}
        {!loading && dateOrders && dateOrders.length > 0 && <OrdersTable orders={dateOrders} />}
        {!loading && noOrders && <NoContent />}
      </Grid>
    );
  }
  else {
    navigate("/items")
  }

};

export default Dashboard;
