
import React from "react";

import {
  AboutUs,
  // Chef,
  FindUs,
  Footer,
  Gallery,
  Header,
  // Intro,
  Laurels,
  SpecialMenu,
} from "../../container";
import  Navbar  from "../../components/navbar/homePageNavbar/homePageNavbar";
import "./home.css";
import { Grid } from "@mui/material";

const HomePage = () => (    
  <Grid container dir={"ltr"} >
    <Navbar />
    <Header />
    <AboutUs />
    <SpecialMenu />
    {/* <Chef /> */}
    {/* <Intro /> */}
    <Laurels />
    <Gallery />
    <FindUs />
    <Footer />
  </Grid>
);

export default HomePage;
