/* eslint-disable array-callback-return */
import { useContext, useEffect } from "react";
import { Grid, Typography } from "@mui/material";




import OrdersContext from "../../context/orders";
import "./hall.scss";
const Hall = () => {
  const { orders, notFinishedOrders, finishedOrders, submittedOrders } = useContext(OrdersContext);

  let submittedOrdersIndex = 0;
  const notFinishedOrdersPlaceHolder = Array.apply(null, Array(14)).map((_, index) => {
    if (notFinishedOrders[index]) {
      return notFinishedOrders[index];
    }
    else {
      return submittedOrders[submittedOrdersIndex++];
    }
  });
  const finishedOrdersPlaceHolder = Array.apply(null, Array(13)).map(function () { });

  return (
    <Grid container justifyContent={"center"} alignItems={"center"} bgcolor={"dark"}>
      <Grid item xs={12} sm={12} lg={5} xl={6} border={"solid 2px #000"} className="hallList" bgcolor={"dark"}>
        <Grid container justifyContent={"space-between"} >
          <Grid item xs={12} sm={12} md={12} bgcolor={"black"} color={"white"} padding={2} display={"flex"} alignItems={"center"}>
            <Typography variant="h4"> {"preparation of orders   جاري التسليم "} </Typography>
          </Grid>
          {
            notFinishedOrdersPlaceHolder && notFinishedOrdersPlaceHolder.map((order, index) => {

              return (
                <Grid
                  key={orders[index] && orders[index].OrderNumber}
                  item xs={6}
                  padding={2}
                  border={"solid 2px #eee"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="center"
                  bgcolor={"dark"}
                >
                  <Typography variant="h5" fontSize={"2rem"} fontWeight={"bolder"} >
                    {order && order.OrderNumber}
                  </Typography>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} lg={5} xl={6} border={"solid 2px #000"} className="finishedList">
        <Grid container padding={3} justifyContent={"space-between"} >
          <Grid item xs={12} sm={12} md={12} padding={4} margin={1} >
            <Typography variant="h4"> {"Ready To Collect    جاهز للتسليم "} </Typography>
          </Grid>
          {
            finishedOrdersPlaceHolder.map((_, index) => {
              return (
                <Grid
                  item
                  xs={index === 0 ? 12 : 6}
                  minHeight={index === 0 ? "15vh" : "10vh"}
                  key={index}
                  padding={2}
                  border={"solid 2px #eee"}
                  textAlign={"center"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h5" fontSize={"2rem"} fontWeight={"bolder"} >
                    {finishedOrders[index] && finishedOrders[index].OrderNumber}
                  </Typography>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    </Grid>
  )
};

export default Hall;
