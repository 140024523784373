import React, { useContext } from 'react'
import OrdersContext from '../../context/orders'
import NoContent from '../../components/noContent';
import { Grid, Typography } from '@mui/material';
import LanguageContext from '../../context/langContext';
import strings from '../../assets/locals/locals';
import OrdersTable from '../../components/ordersTable';

const PaymentHistory = () => {
    const { orders } = useContext(OrdersContext);
    const { lang } = useContext(LanguageContext);
    strings.setLanguage(lang);



    if (orders.length === 0) {
        return <Grid item xs={12}>
            <NoContent />
            <Typography variant="h4"> {strings.thereAreNoOrders}</Typography>
        </Grid>
    }
    else {
        return (
            <Grid container padding={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">{strings.paymentHistory}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <OrdersTable orders={orders} />
                </Grid>
            </Grid>
        )
    }

}

export default PaymentHistory