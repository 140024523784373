import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

// import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import "./Footer.css";
import { Box } from "@mui/material";
import { BsTiktok } from "react-icons/bs";


const soicalMedia = [
  {
    icon: <BsTiktok />,
    text: "Tiktok",
    href: "https://www.tiktok.com/@silinbakeri"
  },
  {
    icon: <FiFacebook />,
    text: "FaceBook",
    href: "https://www.facebook.com/SilinBakeri/"
  },
  {
    icon: <FiInstagram />,
    text: "instagram",
    href: "https://www.instagram.com/silinbakeri/"
  },

]
const Footer = () => (
  <div className="app__footer section__padding" id="login">
    {/* <FooterOverlay /> */}
    {/* <Newsletter /> */}

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">
          <a href="mailto:Bakerimando@gmail.com">Bakerimando@gmail.com</a>
        </p>        <p className="p__opensans">96878984</p>
      </div>

      <div className="app__footer-links_logo">
        <Box
          component="img"
          sx={{
            height: "200px",
            width: "100%",
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="The house from the offer."
          src={images.logo}
        />
        <p className="p__opensans">
          <a href="mailto:Bakerimando@gmail.com">Bakerimando@gmai.com</a>
        </p>
        <img
          src={images.spoon}
          className="spoon__img"
          style={{ marginTop: 15 }}
        />
        <div className="app__footer-links_icons">
          {soicalMedia.map(el => {
            return (
              <a href={el.href} key={el.text} target="_blank">{el.icon}</a>
            )
          })}
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">all week :</p>
        <p className="p__opensans">11:00 am  - 20:00 pm </p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2023 Silin Bakeri. All Rights reserved.</p>
    </div>
  </div>
);

export default Footer;
