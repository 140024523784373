import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  ar: {
    signUp: "أشترك معنا",
    login: "سجل دخول",
    register: "إشترك",
    admin: "أدمن",
    user: "مستخدم",
    kitchen1: " 1 المطبخ",
    kitchen2: " 2 المطبخ",

    addNewCategory: "اضافه فئه جديده",
    AddUser: "اضافه مستخدم جديده",

    successfullLogin: "تم تسجيل الدخول بنجاح",
    registerError: "خطأ في تسجيل الحساب",
    successfullRegistration: "تم تسجيل الحساب بنجاح",
    wrongCredentials: "خطأ في اسم المستخدم او كلمة السر",
    loggedOut: "تم تسجيل الخروج",
    loggingOutError: "خطأ في تسجيل الخروج",
    userName: "إسم المستخدم ",
    userNameEmptyError: "يجب إدخال إسم المستخدم ",
    userNameNotStringError: "إسم المستخدم يجب ان يكون نصاً",

    lastName: "اسم العائلة",
    lastNameEmptyError: "يجب إدخال اسم العائلة",
    lastNameNotStringError: "اسم العائلة يجب ان يكون نصاً",

    role: "الدور",
    roleEmptyError: "يجب إدخال الدور ",
    roleNotValid: "الدور يجب ان يكون إما أدمن أو مستخدم",

    password: "كلمة السر",
    passwordEmptyError: "يجب إدخال كلمة السر",
    passwordLessThanMin: "كلمة السر يجب ان تكون اكثر من 4 حروف",
    passwordNotString: "كلمة السر يجب ان تكون نصاً",

    confirmPassword: "تأكيد كلمة السر",
    confirmPasswordEmptyError: "يجب إدخال التأكد من كلمة السر",
    confirmPasswordLessThanMin: "تأكيد كلمة السر يجب أن تكون أكثر من 4 حروف",
    confirmPasswordNotString: "كلمة السر يجب ان تكون نصاً",
    passAndConfirmAreNotMatch: "كلمة السر يجب ان تتشابه مع تأكيد كلمة السر",

    email: "البريد الالكتروني",
    emailNotEmailFormat: "يجب إدخال سيغة بريد الكتروني صحيحة",
    emailEmptyError: "يجب إدخال بريدك الالكتروني",

    haveAnAccount: "أمتلك حساب بالفعل",
    dontHaveAnAccount: "لا تمتلك حساب , يمكنك",

    logOut: "تسجيل خروج",

    overView: "نبذة عامة",
    items: "العناصر",
    ItemsName: "العناصر",
    totalCustomers: "إجمالي العملاء",
    totalOrders: "إجمالي الطلبات",
    mostSoldItem: "العنصر الأكثر مبيعاً",
    totalProfit: "إجمالي الارباح",

    itemName: "اسم العنصر",
    itemNameIsNotStringError: "اسم العنصر يجب ان يكون نصاً",
    itemNameIsEmpty: "يجب إدخال اسم العنصر",
    itemImageIsRequired: "يجب إدخال صورة العنصر",
    itemAddedSuccessfully: "تم إضافة العنصر بنجاح",
    addItemError: "خطأ في تسجيل العنصر",
    description: "الوصف",
    descriptionIsNotStringError: "الوصف يجب ان يكون نصاً",
    descriptionIsEmptyError: "يجب إدخال وصف العنصر",

    allergy: "حساسية من ",
    allergyIsNotString: "الحساسية يجب ان تكون نصاً",
    allergyIsEmptyError: "يجب إدخال الحساسية",

    price: "السعر",
    priceIsNotNumber: "السعر يجب ان يكون رقم و لا يحتوي علي اي حروف",
    priceIsEmpty: "يجب إدخال السعر",
    priceMustBeGreaterThanZero: "يجب ان يكون السعر اكبر من 0 ",

    submit: "تسجيل",
    addNewItem: "اضافة عنصر جديد",

    categories: "الاصناف",
    category: "الصنف",
    categoryIsEmpty: "يجب إدخال تصنيف العنصر",
    categoryTitle: "اسم الصنف",
    categoryTitlIsNotString: "اسم الصنف يجب ان يكون نصاً ",
    categoryTitleIsEmptyError: "يجب إدخال اسم الصنف",

    all: " جميع العناصر",
    filterItems: "تصفية العناصر بواسطة الصنف",

    orderNumber: "رقم الطلب",
    orderId: "هوية الطلب",
    quantity: "الكمية",
    takeAway: "تاك اواي",
    totalPrice: "إجمالي السعر",
    hall: "الصالة",
    delete: "حذف",
    makeItAvailable: "اضافة العنصر الي القائمة",
    makeItUnavailable: "حذف العنصر من القائمة ",
    available: "متوفر",
    notAvailable: "غير متوفر",
    status: "الحالة",
    statusUpdatedSuccessfully: "تم تغير حالة العنصر بنجاح",
    updateOrders: "إعادة تحميل الطلبات",
    finishedOrders: "الطلبات المنتهيه",
    notFinishedOrders: "طلبات يتم العمل فيها الان",
    waiting: "لائحة الانتظار",

    searchWithOrderId: "البحث عن طلب منتهى",
    orderNumberIsNotNumberError: "من فضلك ادخل رقم الطلب",
    orderNumberCannotBeLessThanZero: "رقم الطلب لا يمكن ان يكون سالباً",
    orderDuration: "المدة المستغرقة",

    adjustmentTitle: "عنوان التعديل",
    adjustmentTitleIsRequired: "من فضلك ادخل عنوان التعديل",
    adjustmentTitleNotString: "عنوان التعديل يجب ان يكون نصاً",
    adjustmentLabelIsNotString: "العنصر يجب ان يكون نصاً",
    adjustmentLabelIsRequired: "يجب أدخال نص العنصر",
    overPriceIsNotANumber: "السعر الاضافي يجب ان يكون عدداً",
    overPriceIsRequired: "يجب إدخال قيمة للسعر الاضافي",
    overPriceMustBeBiggerThanZero: "السعر الاضافي يجب ان يكون اكبر من الصفر",

    needToAddCategoryFirst: "يجب اضافة فئة اولاً",
    addAdjustments: "قم بإضافة تعديلات  ",
    adjustmentsAddedSuccesfully: "تم إضافة التعديلات بنجاح",
    addAdjustmentError: "حدث خطأ في اضافة التعديلات يمكنك تجربة الخدمة لاحقاً ",
    policy: "الشروط و الاحكام",
    deleteCategory: "حذف الفئة",
    paymentHistory: "عمليات الشراء",
    thereAreNoCategories: "عذراً لا يوجد اصناف",
    thereAreNoItems: "عذراً لا يوجد اي عناصر",
    thereAreNoOrders: "عذراً لا يوجد اي  طلبات",
    makeOrder: "اضافة طلب",
    totalItems: "إجمالي العناصر",
    totalCategories: "إجمالي الفئات",
    reset: "ازالة الاختيارات",
    itemCount: "عدد مرات الطلب",
    getOrdersByDate: "عرض طلبات يوم معين",
    orderItems: "عناصر الطلب",
    orderPrice: "سعر الطلب",
    orderDate: "تاريخ الطلب",
    paymentStatus: "حالة الدفع",
    checkPaymentStatus: "التأكد من حالة الدفع",
    userType: "نوع المستخدم",
    admin: "أدمن",
    normal: "عادى",
    userTypeIsEmpty: "برجاء إدخال نوع المستخدم",
    userTypeIsNotString: "نوع المستخدم يجب أن يكون نصاً",
    orderDeletedSuccessfully: "تم حذف الطلب بنجاح",
    orderTake: "توصيل الطلب",
  },
  en: {
    AddUser: "Add New User",
    signUp: "Sign Up",
    login: "Log In",
    register: "Register",
    admin: "Admin",
    user: "User",
    kitchen1: "kitchen 1 ",
    kitchen2: "kitchen 2 ",
    addNewCategory: "add New Category",
    successfullLogin: "Sucessfull Login",
    successfullRegistration: "Successfull Registration",
    registerError: "Registration Error",
    wrongCredentials: "Wrong Credentials",
    loggedOut: "Logged Out Successfully",
    loggingOutError: "Logging Out Error",
    userName: "User Name ",
    userNameEmptyError: "User Name is required",
    userNameNotStringError: "User Name must be a String",

    lastName: "Last Name",
    lastNameEmptyError: "Last Name is required",
    lastNameNotStringError: "Last Name must be a String",

    role: "Role",
    roleEmptyError: "Role is required",
    roleNotValid: "Role must be either admin or user",

    password: "Password",
    passwordEmptyError: "Password is required",
    passwordLessThanMin: "Password must be at least 8 char",
    passwordNotString: "Password must be a string",

    confirmPassword: "Confirm Password",
    confirmPasswordEmptyError: "Confirm Password is Required",
    confirmPasswordLessThanMin: "Password must be at least 4 char",
    confirmPasswordNotString: "Password must be a string",
    passAndConfirmAreNotMatch: "Password and Confirm Password must match",

    email: "Email",
    emailNotEmailFormat: "Enter a valid Email Format",
    emailEmptyError: "Email is Required",

    haveAnAccount: "Already , have an Account",
    dontHaveAnAccount: "Don't have an account , you can ",
    logOut: "Log Out",
    overView: "Overview",
    items: "Items",
    ItemsName: "Order Items ",
    orderNumber: "Order Number ",
    orderId: "Order Id ",
    totalCustomers: "Total Customers",
    totalOrders: "Total Orders",
    mostSoldItem: "Most Sold Item",
    totalProfit: "Total Profit",

    itemName: "Item Name",
    itemNameIsNotStringError: "Item Name must be string",
    itemNameIsEmpty: "Item Name is required",
    itemImageIsRequired: "Item image is required",
    itemAddedSuccessfully: "Item Has Been Added Successfully",
    addItemError: "Add Item Error",
    description: "Description",
    descriptionIsNotStringError: "Description must be string",
    descriptionIsEmptyError: "Decription is Required",

    allergy: "Allergy",
    allergyIsNotString: "Allergy must be string",
    allergyIsEmptyError: "Allergy is Required",

    price: "Price",
    priceIsNotNumber: "Price must be a number",
    priceIsEmpty: "Price is required",
    priceMustBeGreaterThanZero: "Price must be greater than 0",

    submit: "Submit",
    addNewItem: "Add New Item",

    category: "Category",
    categories: "Categories",
    categoryIsEmpty: "Category is required",
    categoryTitle: "Category Title",
    categoryTitlIsNotString: "Category title must be string",
    categoryTitleIsEmptyError: "Category title is required",

    all: "All Items",
    filterItems: "Filter Items by Category",

    quantity: "Quantity",
    takeAway: "Take Away",
    totalPrice: "Total Price",

    hall: "Hall",
    delete: "Delete",
    makeItUnavailable: "Make item unavailable",
    makeItAvailable: "Make item available",
    notAvailable: "Not Available",
    available: "Available",
    status: "Status",
    statusUpdatedSuccessfully: "Status Updated Succesfully",
    updateOrders: "Reload Orders",
    finishedOrders: "Finished Orders",
    notFinishedOrders: "On going Orders",
    waiting: "Waiting List ",
    searchWithOrderId: "Search for a finished order",
    orderNumberIsNotNumberError: "Order Number must be a number",
    orderNumberCannotBeLessThanZero: "Order Number can't bt less than 0",
    orderDuration: "Order Duration ",
    adjustmentTitle: "Adjustment Title",
    adjustmentTitleNotString: "Adjustment Title must be String",
    needToAddCategoryFirst: "You Need to Add a category First",

    adjustmentTitleIsRequired: "Adjustment Title is Required",
    adjustmentLabelIsNotString: "Adjustment Label Must be A String",
    adjustmentLabelIsRequired: "Adjustment Label Is Required",
    overPriceIsNotANumber: "Over Price Must be a Number",
    overPriceIsRequired: "Over Price is Requried",
    overPriceMustBeBiggerThanZero: "Over Price Can't be less than or equal 0 ",
    addAdjustments: "Add Adjustments",
    adjustmentsAddedSuccesfully: "Adjustments Added Successfully",
    addAdjustmentError: "Add Adjustment error , maybe you can try later",
    policy: "Our Policy",
    deleteCategory: "Delete Category",
    paymentHistory: "Payment History",
    thereAreNoCategories: " There are no Categories",
    thereAreNoItems: "There are no Items",
    thereAreNoOrders: "There are no Orders",
    makeOrder: "Make Order",
    totalItems: "Total items",
    totalCategories: "Total Ctegories",
    reset: "reset",
    itemCount: "Number of times requested ",
    getOrdersByDate: "Get Orders by date",
    orderItems: "order items ",
    orderPrice: "Order Price ",
    orderStatus: "Order Status",
    orderDate: "Order Date",
    paymentStatus: "Payment Status",
    checkPaymentStatus: "Check Payment Status",
    userType: " User Type",
    admin: "ADMIN",
    normal: "Normal",
    userTypeIsNotString: "User Type is Not string",
    userTypeIsEmpty: "User Type is Empty",
    orderDeletedSuccessfully: "Order Has Been Deleted Succesfully ",
    orderTake: "Order Take",
  },
});

export default strings;
