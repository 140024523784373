import { useContext } from "react";
import { Button, Grid } from "@mui/material";
import { UserContext } from "../../context/userContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LanguageContext from "../../context/langContext";

import "./navbar.scss";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import DarkModeContext from "../../context/darkModeContext";
import strings from "../../assets/locals/locals";

function NavBar() {
  const { toggleDarkMode } = useContext(DarkModeContext);
  const user = useContext(UserContext);
  const { lang, setLangToAr, setLangToEn } = useContext(LanguageContext);
  const history = useNavigate();

  strings.setLanguage(lang);

  const handleLogout = () => {
    user.logout();
    toast("Success", "Logged Out Successfully");
    history("/");
  };

  return (
    <Grid container justifyContent="end" alignItems="center"> 
      <Grid item xs = {12} sm = {12} md = {6} >
        <Grid container className="items">
          <Grid item xs={6} sm = {6} md = {3} padding = {2}>
            <Button className = "mainBtn" variant="outlined" onClick={handleLogout} fullWidth>
              {strings.logOut}
            </Button>
          </Grid>

          <Grid item xs={6} sm = {6} md = {3} padding = {2} >
            <Button className = "mainBtn" variant="outlined" onClick={setLangToAr} fullWidth >
              لغة عربية
            </Button>
          </Grid>
          <Grid item xs={6} sm = {6} md = {3} padding = {2} >
            <Button className = "mainBtn" variant="outlined" onClick={setLangToEn} fullWidth >
              en
            </Button>
          </Grid>
          <Grid item xs={6} sm = {6} md = {3} padding = {2} display = "flex" alignItems="center" justifyContent="center">
            <DarkModeOutlinedIcon className="icon" onClick={toggleDarkMode} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default NavBar;
