const routes = [
    {
        text: "Home",
        href: "/#home"
    }
    ,
    {
        text: "About",
        href: "/#about"
    }
    ,
    {
        text: "Menu",
        href: "/#menu"
    },
    {
        text: "Awards",
        href: "/#awards"
    }, {
        text: "Contact",
        href: "/#contact"
    }


]

export default routes;