import { createContext, useContext, useEffect, useState } from "react";
import React from "react";

import axios from "axios";
import strings from "../assets/locals/locals";
import { toast } from "react-toastify";
import LanguageContext from "./langContext";
import UserContext from "./userContext";
import MenueContext from "./menue";
import { useNavigate } from "react-router-dom";

const OrdersContext = createContext([]);

export const OrdersContextProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [finishedOrders, setFinishedOrders] = useState([]);
  const [notFinishedOrders, setNotFinishedOrders] = useState([]);
  const [submittedOrders, setSubmittedOrders] = useState([]);
  const { lang } = useContext(LanguageContext);
  const { token } = useContext(UserContext);

  // adding make order page :

  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const { menue } = useContext(MenueContext);

  const [order, setOrder] = useState([]);
  const [orderPrice, setorderPrice] = useState(0);
  const [open, setOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [adjustmentModal, setAdjustmentModal] = useState(false);
  const [orderTake, setOrderTake] = useState("Dine In");
  const [totalPrice, setTotalPrice] = useState(0);
  const history = useNavigate();

  strings.setLanguage(lang);

  useEffect(() => {
    getOrders();
    const getOrdersEvery30s = setInterval(getOrders, 30 * 1000);
    return clearInterval(getOrdersEvery30s);
  }, []);

  // handling the price change :
  useEffect(() => {
    let price = 0;
    order &&
      order.forEach((element) => {
        if (element.addOnPrice) {
          price +=
            (parseInt(element.Price) + parseInt(element.addOnPrice)) *
            parseInt(element.quantity);
        } else {
          price += parseInt(element.Price) * parseInt(element.quantity);
        }
      });
    setorderPrice(price);
  }, [order]);

  const getOrders = () => {
    axios
      .get("/YearSlide.php")
      .then((res) => {
        if (res.data.status !== 0 && Array.isArray(res.data.orders)) {
          setOrders(res.data.orders);
          setTotalPrice(res.data.totalPPrice);
          let ordersResponse = res.data.orders.map((el) => el);
          let finished = ordersResponse.filter(
            (el) =>
              el.Status === 3 &&
              (el.paymentStatus === "RESERVE" ||
                el.paymentStatus === "CAPTURE" ||
                el.paymentStatus === "ADMIN")
          );

          setFinishedOrders(finished);

          let notFinished = ordersResponse.filter(
            (el) =>
              el.Status === 2 &&
              (el.paymentStatus === "RESERVE" ||
                el.paymentStatus === "CAPTURE" ||
                el.paymentStatus === "ADMIN")
          );
          setNotFinishedOrders(notFinished);

          let submittedOrders = ordersResponse.filter(
            (el) =>
              el.Status === 1 &&
              (el.paymentStatus === "RESERVE" ||
                el.paymentStatus === "CAPTURE" ||
                el.paymentStatus === "ADMIN")
          );
          setSubmittedOrders(submittedOrders);
        } else {
          setOrders([]);
          setSubmittedOrders([]);
          setNotFinishedOrders([]);
          setFinishedOrders([]);
        }
      })
      .catch((err) => {});
  };

  const changeStatus = (OrderID, Status) => {
    let orderStatus = 0;
    if (Status === 1) {
      orderStatus = 2;
    } else if (Status === 2) {
      orderStatus = 3;
    } else if (Status === 0) {
      orderStatus = 1;
    }
    axios
      .post("/update_status_order.php", {
        orderID: OrderID,
        newStatus: orderStatus,
      })
      .then((res) => {
        getOrders();
        toast.success(`${strings.statusUpdatedSuccessfully}`, {
          position:
            lang === "ar" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {});
  };

  const deleteOrder = (OrderID) => {
    axios
      .delete("/DeleteOrder_Api.php", {
        data: {
          OrderID,
        },
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        toast.success(`${strings.orderDeletedSuccessfully}`, {
          position:
            lang === "ar" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
        getOrders();
      })
      .catch((err) => {});
  };

  const ordersHandler = (id) => {
    // loading the data set ;
    const item = menue.filter((d) => d.MenuItemID === id);
    // making a copy of orders ;
    item[0].quantity = 1;
    let temp = order.map((el) => el);

    // getting repeated orders ;
    let repeated = temp.filter((e) => e.MenuItemID === id);

    // check if there are repeated orders ;
    if (repeated.length > 0) {
      temp = temp.filter((elem) => elem.MenuItemID !== id);
      setOrder(temp);
    } else {
      // adding the new item ;
      temp.push(item[0]);
      setOrder(temp);
      setAdjustmentModal(true);
    }
  };

  const deCreamentQuantity = (id) => {
    let temp = order.map((el) => el);
    const index = temp.findIndex((el) => el.MenuItemID === id);
    if (temp[index].quantity > 1) {
      temp[index].quantity--;
    } else {
      temp = temp.filter((el) => el.MenuItemID !== id);
    }
    setOrder(temp);
  };

  const inCreamentQuantity = (id) => {
    let temp = order.map((el) => el);
    const index = temp.findIndex((el) => el.MenuItemID === id);
    temp[index].quantity++;
    setOrder(temp);
  };

  const submitOrder = () => {
    setLoading(true);
    if (order && order.length) {
      const orderDate = new Date();
      const allAdjustments = order.map((el, index) => {
        if (el.adjustments) {
          return el.adjustments
            .map((ob) => `${ob.title} : ${ob.adj.label}`)
            .concat(
              order[index].sideDishes &&
                order[index].sideDishes.map((sideDish) => sideDish.ItemName)
            );
        } else {
          return [];
        }
      });
      const data = {
        OrderDate: `${orderDate.getFullYear()}-${
          orderDate.getMonth() + 1
        }-${orderDate.getDate()}  ${orderDate.getHours()}:${orderDate.getMinutes()}:${orderDate.getSeconds()}`,
        status_order: 1,
        menuItemID: order.map((el) => el.MenuItemID),
        quantity: order.map((el) => el.quantity),
        orderTake: orderTake,
        ItemName: order.map((el) => el.ItemName),
        adjustments: allAdjustments,
        totalAmount: orderPrice,
      };
      axios
        .post("https://silinbakeri.net/php/payment/insertOrder.php", data)
        .then(async (res) => {
          setOrderNumber(res.data.orderNumber);
          setLoading(false);
          setOrder([]);
          handleClose();
          getOrders();
          toast.success(`${strings.orderNumber} : ${res.data.orderNumber} `, {
            position:
              lang === "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
          });
          // await delay(20 * 1000);
          // history("/submitted-orders");
        })
        .catch((err) => {});
    } else {
      history("/dashboard");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  // handleChange for the side Dishes ;
  const handleSideDishes = (item, sideDish) => {
    let temp = order.map((el) => el); //
    const index = temp.findIndex((x) => x.MenuItemID === item.MenuItemID);
    const orderItem = temp[index];

    if (!orderItem.addOnPrice) {
      orderItem.addOnPrice = 0;
    }

    if (!orderItem.sideDishes) {
      orderItem.sideDishes = [];
      orderItem.sideDishes.push(sideDish);
    } else {
      orderItem.sideDishes.forEach((sideDishes) => {
        orderItem.addOnPrice -= Number(sideDishes.Price);
      });

      let repeated = orderItem.sideDishes.filter(
        (element) => element.ItemName === sideDish.ItemName
      );
      if (repeated.length > 0) {
        orderItem.sideDishes = orderItem.sideDishes.filter(
          (element) => element.ItemName !== sideDish.ItemName
        );
      } else {
        orderItem.sideDishes.push(sideDish);
      }
    }

    orderItem.sideDishes.forEach((sideDish) => {
      orderItem.addOnPrice += Number(sideDish.Price);
    });

    setOrder(temp);
  };

  // handle Change for Other Adjustments ;
  const handleOrderAdjustments = (adj, title, item) => {
    let temp = order.map((el) => el); //
    const index = temp.findIndex((x) => x.MenuItemID === item.MenuItemID);

    const orderItem = temp[index];

    if (!order.addOnPrice) {
      orderItem.addOnPrice = 0;
    } else {
      orderItem.adjustments.forEach((adjustement) => {
        orderItem.addOnPrice -= Number(adjustement.adj.price);
      });
    }

    if (!orderItem.adjustments) {
      orderItem.adjustments = [];
      orderItem.adjustments.push({ title, adj });
    } else {
      let repeated = orderItem.adjustments.filter(
        (element) => element.title === title
      );
      if (repeated.length > 0) {
        let adjustmentIndex = orderItem.adjustments.findIndex(
          (element) => element.title === title
        );
        orderItem.adjustments.splice(adjustmentIndex, 1);
        orderItem.adjustments.push({ title, adj });
      } else {
        orderItem.adjustments.push({ title, adj });
      }
    }

    orderItem.adjustments.forEach((adjustement) => {
      orderItem.addOnPrice += Number(adjustement.adj.price);
    });
    setOrder(temp);
  };

  // Reseting Adjustments and  side Dishes ;
  const handleAdjustmentsReset = (item) => {
    const temp = order.map((el) => el);
    const index = temp.indexOf((ord) => ord.itemName === item.Name);
    const orderItem = order[index];
    orderItem.adjustments = [];
    orderItem.sideDishes = [];
    orderItem.addOnPrice = 0;

    setOrder(temp);
  };

  const cancelOrder = () => {
    setOrder([]);
  };

  const checkPaymentStatus = (order_id) => {
    axios
      .post("/payment/checkStatus.php", {
        order_id,
      })
      .then((res) => {
        toast.success(`Orders payment status is  .... ${res.payment_status} `, {
          position:
            lang === "ar" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        toast.error(` error update Status.... ${err.message} `, {
          position:
            lang === "ar" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <OrdersContext.Provider
      value={{
        orders,
        getOrders,
        changeStatus,
        finishedOrders,
        notFinishedOrders,
        submittedOrders,
        deleteOrder,
        deCreamentQuantity,
        inCreamentQuantity,
        ordersHandler,
        order,
        orderPrice,
        setTotalPrice,
        totalPrice,
        submitOrder,
        handleClose,
        open,
        handleOpen,
        orderNumber,
        loading,
        adjustmentModal,
        setAdjustmentModal,
        handleSideDishes,
        handleOrderAdjustments,
        handleAdjustmentsReset,
        orderTake,
        setOrderTake,
        cancelOrder,
        checkPaymentStatus,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
export default OrdersContext;
