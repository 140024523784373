import React, { useContext, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Logo from "../../assets/images/logo.png";
import "./sidebar.scss";
import LanguageContext from "../../context/langContext";
import strings from "../../assets/locals/locals";
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import CategoryIcon from '@mui/icons-material/Category';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import UserContext from "../../context/userContext";
const Sidebar = ({ Component }) => {
  const { lang } = useContext(LanguageContext);
  const { adminType } = useContext(UserContext);
  strings.setLanguage(lang);

  const [open, setOpen] = useState(false);

  const toggleSideBar = () => {
    setOpen(!open);
  };

  const routes = [
    {
      icon: <DashboardIcon className="icon" />,
      to: "/dashboard",
      title: strings.overView,
      privillage: "super",
      id: 1,
    },
    {
      icon: <AddIcon className="icon" />,
      to: "/sign-up",
      title: strings.AddUser,
      privillage: "super",
      id: 2,
    },
    {
      icon: <FormatAlignJustifyIcon className="icon" />,
      to: "/items",
      title: strings.items,
      privillage: "normal",
      id: 3,
    },
    {
      icon: <CategoryIcon className="icon" />,
      to: "/categories",
      title: strings.categories,
      privillage: "normal",
      id: 4,
    },
    {
      icon: <SoupKitchenIcon className="icon" />,
      to: "/not-finished-orders",
      title: strings.kitchen2,
      privillage: "normal",
      id: 5,
    }
    ,
    {
      icon: <SoupKitchenIcon className="icon" />,
      to: "/submitted-orders",
      title: strings.kitchen1,
      privillage: "normal",
      id: 6,
    },
    {
      icon: <AccountBalanceIcon className="icon" />,
      to: "/hall",
      title: strings.hall,
      privillage: "normal",
      id: 7,
    }
    ,
    {
      icon: <AttachMoneyIcon className="icon" />,
      to: "/payment-history",
      title: strings.paymentHistory,
      privillage: "super",
      id: 8,
    }
    ,
    {
      icon: <AttachMoneyIcon className="icon" />,
      to: "/make-order",
      title: strings.makeOrder,
      privillage: "normal",
      id: 9,
    }
    ,
    {
      icon: <AccountBalanceIcon className="icon" />,
      to: "/policy",
      title: strings.policy,
      id: 10,
    }
  ];

  return (
    <Grid item xs={0} sm={0} lg={2} className="sideBarContainer">
      {open && <div className="overLay" onClick={toggleSideBar}></div>}
      <div className={open ? "sidebar sidebarOpen" : "sidebar sidebarClosed"}>
        <div className="top">
          <span className="logo">
            <Box
              component="img"
              sx={{
                height: "100px",
                width: "100%",
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="The house from the offer."
              src={Logo}
            />
          </span>
        </div>
        <hr />
        {Component && Component}
        {!Component && <div className="center">
          <ul>
            {routes.map(({ title, icon, to, privillage, id }) => {
              if (adminType === "super" && privillage === "super") {
                return (
                  <li key={id}>
                    <Link to={to}>
                      {icon}
                      <span>{title}</span>
                    </Link>
                  </li>
                );
              }
              else if (privillage === "normal") {
                return (
                  <li key={title}>
                    <Link to={to}>
                      {icon}
                      <span>{title}</span>
                    </Link>
                  </li>
                )
              }
              else {
                return <React.Fragment key={id}></React.Fragment>
              }
            })}
          </ul>
        </div>}

      </div>

      <Button onClick={toggleSideBar} className="toggleSideBarBtn">
        <ArrowRightAltIcon />
      </Button>
    </Grid>
  );
};

export default Sidebar;
