import { CategoriesContextProvider } from "./categories";
import { DarkModeContextProvider } from "./darkModeContext";
import { ItemsContextProvider } from "./itemsContext";
import { LanguageContextProvider } from "./langContext";
import { MenueContextProvider } from "./menue";
import { OrdersContextProvider } from "./orders";
import { UserContextProvider } from "./userContext";

const AllContextsProvider = ({ children }) => {
  return (
    <DarkModeContextProvider>
      <LanguageContextProvider>
        <UserContextProvider>
          <CategoriesContextProvider>
            <ItemsContextProvider>
              <MenueContextProvider>
                <OrdersContextProvider>{children}</OrdersContextProvider>
              </MenueContextProvider>
            </ItemsContextProvider>
          </CategoriesContextProvider>
        </UserContextProvider>
      </LanguageContextProvider>
    </DarkModeContextProvider>
  );
};

export default AllContextsProvider;
