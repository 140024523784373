import bg from '../assets/images/bg.png';
import chef from '../assets/images/chef.png';
import G from '../assets/images/G.png';
import knife from '../assets/images/knife.png';
import logo from '../assets/images/logo.png';
import menu from '../assets/images/menu.png';
import overlaybg from '../assets/images/overlaybg.png';
import spoon from '../assets/images/spoon.svg';
import welcome from '../assets/images/welcome.png';
import findus from '../assets/images/findus.png';
import laurels from '../assets/images/laurels.png';
import award01 from '../assets/images/award01.png';
import award02 from '../assets/images/award02.png';
import award03 from '../assets/images/award03.png';
import award05 from '../assets/images/award05.png';
import sign from '../assets/images/sign.png';
import quote from '../assets/images/quote.png';
import Mankoosh from "../assets/images/Listing_1-removebg-preview (1).png"

import Mankoosh2 from "../assets/images/Listing1.png"
import Syrisk_Lahmacun from "../assets/images/Syrisk_Lahmacun.png"
import Syrisk_Lahmacun_Grønnsaker from "../assets/images/Syrisk_Lahmacun_Grønnsaker.png"
import Syrisk_Sausage from "../assets/images/Syrisk_Sausage.png"
import Zaatar_m_Ost from "../assets/images/Zaatar_m_Ost.png"

export default {
  bg,
  chef,
  G,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  Mankoosh,
  Mankoosh2 ,
  Syrisk_Lahmacun ,
  Syrisk_Lahmacun_Grønnsaker ,
  Syrisk_Sausage ,
  Zaatar_m_Ost,
};
