import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import OrdersContext from "../context/orders";
import strings from "../assets/locals/locals";
import LanguageContext from "../context/langContext";
const Order = ({
  OrderNumber,
  OrderID,
  Quantity,
  Status,
  TimeDifference,
  SelectedItems
}) => {
  const { changeStatus } = useContext(OrdersContext);
  const { lang } = useContext(LanguageContext);
  strings.setLanguage(lang);

  // calculating the order duration :
  let days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0;

  if (TimeDifference) {
    seconds = Math.floor(TimeDifference % 60);
    minutes = Math.floor((TimeDifference % 3600) / 60);
    hours = Math.floor((TimeDifference % 86400) / 3600);
    days = Math.floor((TimeDifference % (86400 * 30)) / 86400);
  }

  return (
    <Button fullWidth onClick={() => changeStatus(OrderID, Status)}>
      <Card sx={{ minWidth: "100%" }} className="hallCard">
        <CardContent className="cardContent" dir="ltr">
          <Typography
            sx={{ fontSize: "2rem" }}
            color="#fff"
            bgcolor={"#1976d2"}
            gutterBottom
          >
            {OrderNumber}
          </Typography>
          <Typography variant="h5" component="div"></Typography>
          {SelectedItems &&
            SelectedItems.map(({ ItemName, Adjustments }, index) => {
              return (
                <Grid container textAlign={"start"} key={`${OrderID} ${ItemName}`}>
                  <Grid
                    container
                    borderBottom={"1px solid #eee"}
                    padding={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Grid item xs={2} lg={2} fontSize={"1.5rem"}>
                      ({JSON.parse(Quantity)[index]})
                    </Grid>
                    <Grid item xs={10} lg={10}>

                      <Typography variant="p" display={"block"} fontSize={"1.3rem"}>
                        <strong>{ItemName}</strong>
                        <ul>
                          {Adjustments && typeof (Adjustments) === "string" ? Adjustments : Adjustments.map(el => {
                            if (el) {
                              return (
                                <li key={el}> {el} </li>
                              )
                            }
                          })}
                        </ul>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              padding={2}
              fontWeight={"bolder"}
            >
              <Typography variant="p" display="flex" justifyContent="center" fontSize={"1rem"}>
                {strings.orderDuration} : {` ${days} D : ${hours} H : ${minutes}  M : ${seconds} S`}</Typography>
            </Grid>
          </Grid>
        </CardContent>

      </Card>
    </Button>
  );
};
export default Order;