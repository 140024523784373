import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import images from "../../../constants/images";
import "./homePageNavbar.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import routes from "./routes";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <Box
          component="img"
          sx={{
            height: "100px",
            width: "100%",
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="The house from the offer."
          src={images.logo}
        />
      </div>
      <ul className="app__navbar-links">
        {routes.map((el, index) => {
          return (
            <li className="p__opensans" key={el.text + index}>
              <a href={el.href}>{el.text}</a>
            </li>
          )
        })}
        <li className="p__opensans">
          <Link to="/our-policy">Policy</Link>
        </li>
      </ul>

      <div className="app__navbar-login">
        <Link to="/2hvcEh7TidLCu7" className="p__opensans">
          Log In
        </Link>
        <div />
        <a href="https://silin.silinbakeri.net/" className="p__opensans" target="_blank">
          Make Order
        </a>
      </div>


      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              {routes.map((el, index) => {
                return (
                  <li className="p__opensans" key={el.text + index}>
                    <a href={el.href}>{el.text}</a>
                  </li>
                )
              })}
              <li className="p__opensans">
                <Link to="/our-policy">Policy</Link>
              </li>

              <li className="p__opensans">
                <Link to="/2hvcEh7TidLCu7" >
                  Log In
                </Link>

              </li>

              <li className="p__opensans">
                <a href="https://silin.silinbakeri.net/"  target="_blank" rel="noopener" >
                  Make Order
                </a>
              </li>

            </ul>

          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
